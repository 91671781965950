//import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/NotFoundPage.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../components/Home.vue'
//import home from '../components/Index.vue'
//import signin from '../components/Signin.vue'
//import animations from '../components/Animations.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: home,
  },
 // {
   // path: '/signin',
    //component: signin,
 // },
 // {
  //  path: '/animations',
  //  component: animations,
 // },
  
  { path: '*', component: NotFound }
]

 

export default new VueRouter({mode: 'history', routes, linkActiveClass: 'nav-item active'})
