<template>
  <div id="app">
    
    <LeftSidebar></LeftSidebar>
    <!--<top-nav></top-nav>-->
    
    <router-view></router-view>
    <!--<footer-nav></footer-nav>-->
    <cookies></cookies>
    
  </div>
</template>

<script>
//import TopNav from './components/TopNavBar.vue'
//import footerNav from './components/Footer.vue'
import LeftSidebar from "./components/LeftSidebar";
import Cookies from './components/Cookies.vue'
export default {
  name: 'App',
  components: {
  //  TopNav,
  //  footerNav,
    LeftSidebar,
    Cookies,
  }
}
</script>

<style>

</style>
