<template>
  <vue-cookie-accept-decline
    :ref="'myPanel1'"
    :elementId="'myPanel1'"
    :debug="false"
    :position="'bottom-left'"
    :type="'floating'"
    :disableDecline="false"
    :transitionName="'slideFromBottom'"
    :showPostponeButton="false"
    @status="cookieStatus"
    @clicked-accept="cookieClickedAccept"
    >
 
    <!-- Optional -->
    <div slot="postponeContent">
        &times;
    </div>
 
    <!-- Optional -->
    <div slot="message">
        We use cookies to ensure you get the best experience on our website.
    </div>
 
    <!-- Optional -->
    <div slot="declineContent">
       DECLINE
    </div> 
 
    <!-- Optional -->
    <div slot="acceptContent">
        ACCEPT
    </div>
</vue-cookie-accept-decline>
  
</template>

<script>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
export default {
  components: {
   VueCookieAcceptDecline
  },
  methods:{
      cookieClickedAccept(){



      },
      cookieStatus(i){
          console.log(i)
      }

  },
}
</script>
